<template>
  <div id="main_MyOrder_pay" style="width:1200px; margin:0 auto">
    <el-container style="height: 100%" class="head_info_bar">
      <el-header height="auto">
        <fw-header titles="线下支付" :headers="{ back: true }" />
      </el-header>
      <div style="margin:15px 0">
        <el-alert
          title="选择一个付款数据时，可以选择多张回单；选择多个付款数据时，只能选择一张回单"
          type="warning"
          show-icon
          :closable="false"
        ></el-alert>
      </div>
      <el-main v-if="dataInfo">
        <div class="bar">
          <div class="divs">
            付款单号
          </div>
          <div class="ellipsisText" :title="serial_number">
            {{ serial_number || "/" }}
          </div>
        </div>

        <fw-title titles="付款信息" style="padding-top: 20px" />

        <div class="bar_box">
          <div class="bar_col">
            <div class="divs">
              币种
            </div>
            <div>
              人民币
            </div>
          </div>
          <div class="bar_col">
            <div class="divs">
              所属公司
            </div>
            <div class="ellipsisText" :title="dataInfo.buyer_company_name">
              {{ dataInfo.buyer_company_name || "/" }}
            </div>
          </div>
          <div class="bar_col">
            <div class="divs">
              应付金额
            </div>
            <div
              class="ellipsisText"
              style="color:#f74848;font-weight:bold;font-size:16px"
              :title="dataInfo.order_amount"
            >
              {{ dataInfo.order_amount | formatMoney }} (大写:{{
                dataInfo.order_amount | dealBigMoney1
              }})
            </div>
          </div>
        </div>
        <div>
          <fw-title titles="收款方信息" />
          <div class="gather">
            <div v-if="showAccount" class="show_account">
              请先设置收款账户
            </div>
            <vxeTable :data="tableListSeller" id="Seller">
              <vxe-column width="160" title="收款人编号" align="left">
                <template #default="{ row }">
                  <p>
                    {{ row.seller_company_code || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column width="220" title="收款人" align="left">
                <template #default="{ row }">
                  <p>
                    {{ row.seller_company_name || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column width="220" title="收款账户名" align="left">
                <template #default="{ row }">
                  <p>
                    {{ row.payee_name || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column width="200" title="开户行" align="left">
                <template #default="{ row }">
                  <p>
                    {{ row.payee_bank || "--" }}
                  </p>
                </template>
              </vxe-column>
              <vxe-column width="200" title="开户账号" align="left">
                <template #default="{ row }">
                  <p>
                    {{ row.payee_account || "--" }}
                  </p>
                </template>
              </vxe-column>
            </vxeTable>
          </div>
        </div>
        <div>
          <fw-title titles="订单明细" />
          <vxe-table
            style="min-height:10vh"
            class="mytable-scrollbar"
            stripe
            highlight-hover-row
            size="small"
            id="toolbar_demo3"
            :row-config="{ height: 120 }"
            ref="xTable1"
            :data="tableListOrder"
          >
            <vxe-column
              fixed="left"
              type="seq"
              title="序号"
              width="50"
              align="center"
              field="xuhao"
            ></vxe-column>
            <vxe-column min-width="160">
              <template #header>
                <p>订单编号</p>
                <p>订单业务状态</p>
                <p>下单时间</p>
              </template>
              <template #default="{ row }">
                <p class="ellipsisText" :title="row.serial_number">
                  {{ row.serial_number || "--" }}
                </p>
                <el-tag type="warning" v-if="row.order_status == 10">待收单</el-tag>
                <el-tag type="warning" v-else-if="row.order_status == 20">
                  待付款
                </el-tag>
                <el-tag type="success" v-else-if="row.order_status == 30">
                  已付款
                </el-tag>
                <el-tag type="warning" v-else-if="row.order_status == 40">
                  待发货
                </el-tag>
                <el-tag type="warning" v-else-if="row.order_status == 50">
                  待收货
                </el-tag>
                <el-tag type="warning" v-else-if="row.order_status == 60">
                  已收货
                </el-tag>
                <el-tag type="info" v-else-if="row.order_status == 70">
                  已取消
                </el-tag>
                <el-tag type="danger" v-else-if="row.order_status == 80">
                  已关闭
                </el-tag>
                <el-tag type="danger" v-else-if="row.order_status == 90">
                  已删除
                </el-tag>
                <p v-else>
                  --
                </p>
                <p class="ellipsisText" :title="row.created_at">
                  {{ row.created_at || "--" }}
                </p>
              </template>
            </vxe-column>

            <vxe-column min-width="140">
              <template #header>
                <p>支付方式</p>
                <p>来源</p>
                <p>生效时间</p>
              </template>
              <template #default="{ row }">
                <p>
                  <span style="background-color: #4387F8" class="colors"></span>
                  <span style="color: #4387F8">
                    {{ (row.order_model == 1 ? "先款后货" : "先货后款") || "--" }}
                  </span>
                </p>
                <div>
                  <template v-if="row.source == 1">
                    自主新增
                  </template>
                  <template v-else-if="row.source == 2">
                    企业购物车
                  </template>
                  <template v-else-if="row.source == 3">
                    工业电商
                  </template>
                  <template v-else>
                    --
                  </template>
                </div>
                <p class="ellipsisText " :title="row.created_at || '--'">
                  {{ row.created_at || "--" }}
                </p>
              </template>
            </vxe-column>
            <vxe-column min-width="140">
              <template #header>
                <p>采购方名称</p>
                <p>统一社会信用代码</p>
              </template>
              <template #default="{ row }">
                <p class="ellipsisText " :title="row.buyer_company_name || '--'">
                  {{ row.buyer_company_name || "--" }}
                </p>
                <p class="ellipsisText " :title="row.buyer_company_code || '--'">
                  {{ row.buyer_company_code || "--" }}
                </p>
              </template>
            </vxe-column>
            <vxe-column min-width="140">
              <template #header>
                <p>销售方名称</p>
                <p>统一社会信用代码</p>
              </template>
              <template #default="{ row }">
                <p class="ellipsisText " :title="row.seller_company_name || '--'">
                  {{ row.seller_company_name || "--" }}
                </p>
                <p class="ellipsisText " :title="row.seller_company_code || '--'">
                  {{ row.seller_company_code || "--" }}
                </p>
              </template>
            </vxe-column>
            <vxe-column min-width="140">
              <template #header>
                <p>订单总额</p>
                <p>付款金额</p>
                <p>发货金额</p>
                <p>收货金额</p>
              </template>
              <template #default="{ row }">
                <p class="ellipsisText" :title="row.order_amount">
                  {{ row.order_amount | formatMoney }}
                </p>
                <p class="ellipsisText" :title="row.pay_amount">
                  {{ row.pay_amount | formatMoney }}
                </p>
                <p class="ellipsisText" :title="row.send_amount">
                  {{ row.send_amount | formatMoney }}
                </p>
                <p class="ellipsisText" :title="row.receipt_amount">
                  {{ row.receipt_amount | formatMoney }}
                </p>
              </template>
            </vxe-column>

            <vxe-column min-width="160">
              <template #header>
                <p>本次付款金额</p>
              </template>
              <template #default="{ row }">
                <template v-if="row.is_input">
                  <div style="margin-bottom:10px">
                    <el-input-number
                      v-model="row.pay_num"
                      controls-position="right"
                      :precision="2"
                      :step="0.01"
                      :min="0"
                      :max="Number(row.order_amount)"
                      style="width: 140px"
                    ></el-input-number>
                  </div>
                </template>
                <template v-else>
                  <h4 class="ellipsisText" style="color:#F74848">
                    {{ row.pay_num | formatMoney }}
                  </h4>
                </template>
              </template>
            </vxe-column>
            <vxe-column fixed="right" min-width="100">
              <template #header>
                <p>操作</p>
              </template>
              <template #default="{ row }">
                <template v-if="row.is_input">
                  <span class="color" style="margin-right:10px" @click="confirm(row)">确认</span>
                  <span class="color" @click="cancel(row)">取消</span>
                </template>
                <template v-else>
                  <p class="color" @click="editBtn(row)">编辑</p>
                </template>
              </template>
            </vxe-column>
          </vxe-table>

          <el-row>
            <el-col :span="24" align="right" class="ftmy">
              <div class="divs">
                <span>
                  本次付款金额合计:
                </span>
                <span
                  class="ellipsisText"
                  style="color:#f74848;font-weight:bold;font-size:16px"
                  v-if="totalPrice"
                >
                  {{ totalPrice | formatMoney }} (大写:{{ totalPrice | dealBigMoney1 }})
                </span>
                <span v-else>
                  --
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div>
          <fw-title titles="交易信息" />
          <el-row>
            <el-col :span="24" align="right">
              <!-- <el-button class="btn" size="small" plain @click="showDialog">选择回单</el-button> -->
              <el-button class="btn" size="small" plain @click="showDialog(1)">智能识票</el-button>
              <el-button class="btn" size="small" plain @click="showDialog(2)">手工录入</el-button>
            </el-col>
            <el-col :span="24" style="margin:15px 0">
              <vxe-table
                style="min-height:10vh"
                class="mytable-scrollbar"
                stripe
                highlight-hover-row
                size="small"
                id="toolbar_demo2"
                :row-config="{ height: 120 }"
                ref="xTable2"
                :data="tableListTrade"
              >
                <vxe-column
                  fixed="left"
                  type="seq"
                  title="序号"
                  min-width="60"
                  align="center"
                ></vxe-column>
                <vxe-column min-width="320" align="left">
                  <template #header>
                    <p>收款人</p>
                    <p>收款开户行</p>
                    <p>收款账号</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText" :title="`${row.collection_name || '--'}`">
                      {{ row.collection_name || "--" }}
                    </p>
                    <p class="ellipsisText" :title="`${row.collection_bank}`">
                      {{ row.collection_bank }}
                    </p>
                    <p class="ellipsisText" :title="`${row.collection_number || '--'}`">
                      {{ row.collection_number || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="320" align="left">
                  <template #header>
                    <p>付款人</p>
                    <p>付款开户行</p>
                    <p>付款账号</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText" :title="`${row.pay_name || '--'}`">
                      {{ row.pay_name || "--" }}
                    </p>
                    <p class="ellipsisText" :title="`${row.pay_bank || '--'}`">
                      {{ row.pay_bank || "--" }}
                    </p>
                    <p class="ellipsisText" :title="`${row.pay_number || '--'}`">
                      {{ row.pay_number || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="200" align="left">
                  <template #header>
                    <p>结算方式</p>
                    <p>交易/票据金额</p>
                    <p>交易日期</p>
                    <p>流水凭证号/票号</p>
                  </template>
                  <template #default="{ row }">
                    <p>
                      线下支付
                    </p>
                    <p class="ellipsisText" :title="`${row.amount}`">
                      {{ row.amount | formatMoney }}
                    </p>
                    <p class="ellipsisText" :title="`${row.transaction_date || '--'}`">
                      {{ row.transaction_date || "--" }}
                    </p>
                    <p class="ellipsisText" :title="`${row.voucher || '--'}`">
                      {{ row.voucher || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="250" align="left" title="交易摘要">
                  <template #default="{ row }">
                    <p class="ellipsisText" :title="`${row.abstract || '--'}`">
                      {{ row.abstract || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="80" title="操作" fixed="right">
                  <template #default="{row, $rowIndex}">
                    <p>
                      <el-link
                        @click="deleteRow(row, $rowIndex)"
                        class="links"
                        :underline="false"
                        type="primary"
                      >
                        删除
                      </el-link>
                    </p>
                  </template>
                </vxe-column>
              </vxe-table>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer style="margin: 30px 0; height: auto">
        <el-row>
          <el-col align="center" :span="24">
            <el-button style="width: 88px" size="small" @click="goback">取消</el-button>
            <el-button style="width: 88px" size="small" type="primary" @click="submit">
              提交
            </el-button>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <!-- <fw-chosereceipt ref="fwchosereceipt" /> -->
  </div>
</template>

<script>
export default {
  name: "orderOfflinePay",
  components: {},
  props: {},
  created() {},
  mounted() {
    this.getBasicInfo();
  },
  data() {
    return {
      dataInfo: null,
      serial_number: null,
      tableListSeller: [],
      tableListOrder: [],
      tableListTrade: [],
      order_amount: 0, //应付金额
      showAccount: false,
    };
  },
  activated() {
    if (this.$route.params.datas) {
      console.log(this.$route.params.datas, 555555555555555555);
      let val = JSON.parse(JSON.stringify(this.$route.params.datas));

      let data = {
        collection_name: val.payee_name, //收款人、户名
        collection_bank: val.payee_bank, //收款开户行
        collection_number: val.payee_number, //收款账号
        voucher: val.serial_number, //流水单号
        annex: val.files, //附件json，前端自定义
        ...val,
      };
      this.tableListTrade.push(data);
    }
    if (this.$route.params.arrs) {
      console.log(this.$route.params.arrs, 555555555555555555);
      let val = JSON.parse(JSON.stringify(this.$route.params.arrs));
      let arr = val.map(v => {
        let data = {
          collection_name: v.res.payee_name, //收款人、户名
          collection_bank: v.res.payee_bank, //收款开户行
          collection_number: v.res.payee_number, //收款账号
          voucher: v.res.serial_number, //流水单号
          annex: v.res.files, //附件json，前端自定义
          ...v.res,
        };
        return data;
      });
      this.tableListTrade = [...this.tableListTrade, ...arr];
    }
  },
  watch: {},
  computed: {
    totalPrice() {
      let sum = this.tableListOrder.reduce(function(total, item) {
        return total + Number(item.pay_num);
      }, 0);
      return sum;
    },
  },
  methods: {
    getBasicInfo() {
      const obj = JSON.parse(sessionStorage.getItem("payingIdsMy"));
      let ids = obj.ids;
      this.$axios.postJSON(`/shop/public/common/personal/list`, { id: ids }).then(res => {
        if (res.data) {
          console.log(res.data);
          let data = res.data.data;
          this.tableListOrder = data.map(v => {
            v["is_input"] = true;
            v["isAdd"] = true;
            v["pay_num"] = Number(v.order_amount) - Number(v.pay_amount);
            return v;
          });
          this.order_amount = this.tableListOrder.reduce(function(total, item) {
            return total + Number(item.payable_amount);
          }, 0);
          this.dataInfo = data[0];
          let department_id = Number(this.dataInfo.seller_department_id);
          let company_id = Number(this.dataInfo.seller_company_id);
          this.$axios
            .postJSON(`/ocr/finance/public/account/bank/popup_company/${company_id}`, {
              department_id: [department_id],
            })
            .then(res2 => {
              if (res2.code == 200) {
                if (res2.data.data.length > 0) {
                  let data = res2.data.data[0];
                  console.log(data, "data");
                  let obj = {
                    seller_company_name: data.department_name,
                    seller_company_code: this.dataInfo.seller_company_code,
                    seller_department_id: data.department_id,
                    payee_name: data.name,
                    payee_bank: data.bank,
                    payee_account: data.account,
                  };
                  this.tableListSeller.push(obj);
                } else {
                  this.showAccount = true;
                }
              }
            });

          this.getNumber();
        }
      });
    },
    getNumber() {
      //获取编号
      this.$axios
        .get("/api/rule/get_rule_number", {
          app_modular: "sc_pay",
        })
        .then(res => {
          console.log(res);
          if (res.code == 200) {
            if (res.data) {
              this.serial_number = res.data.num[0];
            }
          }
        });
    },
    confirm(row) {
      console.log(row);
      let { pay_num } = row;
      if (!pay_num) {
        return this.$message.error("请输入本次付款金额");
      }

      row.is_input = false;
      row.isAdd = false;
    },
    cancel(row) {
      if (row.isAdd) {
        return this.$message.error("请输入本次付款金额");
      }

      let { pay_num } = row.saveData;
      row.pay_num = pay_num;
      row.is_input = false;
    },
    editBtn(row) {
      let { pay_num } = row;
      row.saveData = {
        pay_num,
      };
      row.is_input = true;
    },
    showDialog(num) {
      this.$router.push({
        path: "/myOrder/offlinePay/receipt",
        query: { datastype: num },
      });
    },
    deleteRow(row, index) {
      this.$confirm(`是否确认删除此条交易信息？`, "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let api = `/ocr/personal_bank_receipt/delete`;
          this.$axios.deltJSON(api, { id: [row.id] }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.tableListTrade.splice(index, 1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    goback() {
      this.$router.push("/myOrder");
    },
    submit() {
      if (this.tableListTrade.length == 0) {
        return this.$message.error("请选择回单");
      }
      let flag = this.tableListOrder.every(v => v.is_input == false);
      if (!flag) {
        return this.$message.error("请确认本次付款金额");
      }
      console.log(this.tableListTrade);
      let pay_company_code = this.tableListOrder[0].buyer_company_code;
      let payee_company_code = this.tableListOrder[0].seller_company_code;
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo);
      let arr = this.tableListTrade.map(v => {
        let obj = {
          order_id: 0, //订单id
          flow_serial_number: v.liushui_num, //流水单号
          receipt_id: v.id || 0, //回单id
          transaction_date: v.transaction_date, //交易日期
          pay_amount: this.totalPrice, //付款金额
          total_amount: v.amount, //回单总金额
          settlement_id: "2", //支付方式id1.线上支付；2.线下支付
          settlement_method: "线下支付", //支付方式名称
          pay_company_id: 0, //付款企业id
          pay_company_code: pay_company_code, //付款企业统一社会信用代码
          payer_name: v.pay_name, //付款方名称
          payer_serial_number: "", //付款方编号
          pay_bank: v.pay_bank, //付款开户行
          pay_number: v.pay_number, //付款账号
          payee_company_id: 0, //收款企业id
          payee_company_code: payee_company_code, //收款企业统一社会信用代码
          payee_name: v.collection_name, //收款方名称
          payee_serial_number: "", //付款方编号
          payee_bank: v.collection_bank, //收款开户行
          payee_number: v.collection_number, //收款账号
          handled_by: userInfo.user_name ? userInfo.user_name : userInfo.user_mobile, //经办人
          handled_staff_id: v.created_staff_id || 0, //经办人员工id
          handled_at: v.created_at || null, //经办时间
          annex: v.annex, //附件json，前端自定义
        };
        return obj;
      });
      const obj = JSON.parse(sessionStorage.getItem("payingIdsMy"));
      let ids = obj.ids;
      let pay_list = [];
      ids.forEach(id => {
        pay_list = arr.map(v => {
          v.order_id = id;
          return v;
        });
      });
      console.log(pay_list, "pay_listpay_listpay_list");
      let params = {
        pay_list,
      };
      let api = "/shop/public/common/personal/offline_pay";

      this.$axios.postJSON(api, params).then(res => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          setTimeout(() => {
            this.$router.push("/myOrder");
          }, 800);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#main_MyOrder_pay {
  padding-bottom: 1px;
  box-sizing: border-box;
  height: 92vh;
  .zone {
    min-height: 10vh;
    height: auto;
  }
}
.head_info_bar {
  background-color: #fff;
  padding: 0 15px;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.bar {
  background-color: #fafafa;
  padding: 5px 15px;
  box-sizing: border-box;
  line-height: 35px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .el-col {
    margin-bottom: 15px;
  }

  .divs {
    display: flex;
    align-items: center;
    min-width: 100px;
    margin: 25 0mm;
  }

  > div:last-child {
    flex: 1;
    word-wrap: break-word;
    /* 当单词太长时自动换行 */
    word-break: break-all;
    /* 使单词在任意字符内换行，避免单词被截断 */
  }
}
.bar_box {
  background-color: #fafafa;
  padding: 5px 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .bar_col {
    display: flex;
    align-items: center;
    line-height: 35px;
    .divs {
      display: flex;
      align-items: center;
      min-width: 100px;
      i {
        margin: 0 5px;
      }
    }
    > div:last-child {
      flex: 1;
      word-wrap: break-word; /* 当单词太长时自动换行 */
      word-break: break-all; /* 使单词在任意字符内换行，避免单词被截断 */
    }
  }
}
.ftmy {
  background-color: #fafafa;
  padding: 5px 15px;
  box-sizing: border-box;
  line-height: 35px;
  margin-bottom: 15px;
  .divs {
    min-width: 100px;
  }
}
.gather {
  padding: 0px 0 0px 25px;
  width: 1000px;
}
.color {
  color: #4387f8;
  cursor: pointer;
}
.btn {
  height: 28px;
  line-height: 28px;
  padding: 0 8px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(67, 135, 248, 1);
  font-size: 14px;
  text-align: center;
  font-family: Roboto;
  border: 1px solid rgba(67, 135, 248, 1);
}
.show_account {
  margin-bottom: 12px;
  font-weight: bolder;
  font-size: 18px;
  color: #f80404;
}
</style>
